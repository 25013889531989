import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({data}) {
    const post = data.markdownRemark

    return (
      <Layout>
        <SEO title={post.frontmatter.title} />
        <div
          style={{
            marginTop: `70px`,
            marginBottom: `110px`,
          }}
        >
          <p
            style={{
              fontSize: `19px`,
              color: `#78757a`,
            }}
          >
            {post.frontmatter.date}
          </p>
          <h3
            style={{
              fontSize: `40px`,
              fontWeight: '700',
              textDecoration: `underline #f67300`,
            }}
          >
            {post.frontmatter.title}
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: post.html }}
            style={{
                fontSize: `19px`,
            }} />
        </div>
      </Layout>
    )
}

export const postQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path}}) {
            html
            frontmatter {
                path
                title
                date
            }
        }
    }
`